<template lang="pug">
    <!--  BEGIN MAIN CONTAINER  -->
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
        div.search-overlay
        
        <!--  BEGIN CONTENT PART  -->
        div.main-content(id="content")
            div.layout-px-spacing
                div.page-header
                    div.page-title
                        h3 {{ $t('headers.centers')}}
                    
                    div
                        nav.breadcrumb-one(aria-label="breadcrumb")
                          ol.breadcrumb
                                li.breadcrumb-item
                                    router-link(to="/pages/home") {{ $t('headers.dashboard')}}
                                li.breadcrumb-item.active(aria-current="page") {{ $t('headers.centers')}}

                div.row.mt-5.pb-5
                                   
                    router-link.infobox-1.col-md-3.widget.bg-white(to="/pages/myAdminCenters")
                        div
                            div.info-icon
                                svg.feather.feather-cpu(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                    rect(x="4" y="4" width="16" height="16" rx="2" ry="2")
                                    rect(x="9" y="9" width="6" height="6")
                                    line(x1="9" y1="1" x2="9" y2="4")
                                    line(x1="15" y1="1" x2="15" y2="4")
                                    line(x1="9" y1="20" x2="9" y2="23")
                                    line(x1="15" y1="20" x2="15" y2="23")
                                    line(x1="20" y1="9" x2="23" y2="9")
                                    line(x1="20" y1="14" x2="23" y2="14")
                                    line(x1="1" y1="9" x2="4" y2="9")
                                    line(x1="1" y1="14" x2="4" y2="14")
                            h5.info-heading {{ $t('dashboardcenter.centermanager')}}
                            p.info-text
                        

                    router-link.infobox-1.col-md-3.widget.bg-white(to="/pages/centersWithRequest")
                        div
                            div.info-icon
                                svg.feather.feather-clipboard(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                    path(d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2")
                                    rect(x="8" y="2" width="8" height="4" rx="1" ry="1")
                            h5.info-heading {{ $t('dashboardcenter.requestcener')}}
                            p.info-text
                                    
                                    
                    
                    router-link.infobox-1.col-md-3.widget.bg-white(to="/pages/centers")
                        div
                            div.info-icon
                                svg(version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"	viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve")
                                    path(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" d="M5.3,6.8l10.2,0c0.9,0,1.7,0.8,1.7,1.7v10.2c0,0.9-0.8,1.7-1.7,1.7l-10.2,0c-0.9,0-1.7-0.8-1.7-1.7l0-10.2	C3.6,7.5,4.3,6.8,5.3,6.8z")
                                    rect(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" x="7.8" y="11" width="5.1" height="5.1")
                                    line(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" x1="7.8" y1="4.2" x2="7.8" y2="6.8")
                                    line(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" x1="13" y1="4.2" x2="13" y2="6.8")
                                    line(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" x1="7.8" y1="20.4" x2="7.8" y2="23")
                                    line(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" x1="13" y1="20.4" x2="13" y2="23")
                                    line(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" x1="17.2" y1="11" x2="19.8" y2="11")
                                    line(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" x1="17.2" y1="15.3" x2="19.8" y2="15.3")
                                    line(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" x1="1" y1="11" x2="3.6" y2="11")
                                    line(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" x1="1" y1="15.3" x2="3.6" y2="15.3")
                                    line(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" x1="20.5" y1="1" x2="20.5" y2="6.3")
                                    line(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" x1="17.9" y1="3.6" x2="23.1" y2="3.6")
                            h5.info-heading {{ $t('dashboardcenter.createcenter')}}
                            p.info-text
</template>

<script>
/* eslint-disable */

export default {
    name: 'DashboardCenter',
    components: {
    },
    data() {
        return {
        }
    },
    methods: {
    },
    mounted() {

    }
}
</script>
